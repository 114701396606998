<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">购买记录</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="购买类型">
					<a-select placeholder="请选择购买类型" v-model="query.type">
						<a-select-option :value="1" :key="1">
							自购
						</a-select-option>
						<a-select-option :value="2" :key="2">
							赠送
						</a-select-option>
						<a-select-option :value="4" :key="4">
							实体卡
						</a-select-option>
						<a-select-option :value="3" :key="3">
							系统发放
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="卡号">
					<a-input v-model="query.card_no" placeholder="请输入卡号"></a-input>
				</a-form-model-item>
				<a-form-model-item label="下单用户">
					<a-input v-model="query.phone" placeholder="请输入手机号"></a-input>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>

			<span slot="type" slot-scope="text, record">
				<div v-if="record.type === 1">
					自购
				</div>
				<div v-if="record.type === 2">
					赠送
				</div>
				<div v-if="record.type === 3">
					系统发放
				</div>
				<div v-if="record.type === 4">
					实体卡
				</div>
			</span>
		</a-table>
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10
			},
			customer_id: null,
			pagetotal: 0,
			checkValue: [],
			packageList: [],
			tags: ['Tag1', 'Tag2', 'Tag3'],
			inputValue: '',
			other: '',
			columns: [
				{
					title: '卡号',
					dataIndex: 'card_no',
				},
				{
					title: '购买类型',
					scopedSlots: {
						customRender: 'type',
					},
				},
				{
					title: '下单用户',
					dataIndex: 'pay_name'
				},
				{
					title: '绑定用户',
					dataIndex: 'phone'
				},
				{
					title: '激活时间',
					dataIndex: 'create_at_str'
				},
			],
			data: [],
		}
	},
	created() {
		this.getList()
	},
	methods: {
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit
			}
			this.getList()
		},
		handleChange(value) {
			console.log(`Selected: ${value}`);
		},
		getList() {
			this.$post(domain + '/admin/v1/card/buyList', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList()
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
